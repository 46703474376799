
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import service from "../../images/service.jpeg";
import install from "../../images/install.jpeg";
import repair from "../../images/repair.jpeg";
import acimage from "../../images/acimage.jpeg";
import acimage1 from "../../images/acimage1.jpeg";
import './ServiceType.css';
import ac from "../../images/13-SM636852.png";
import { useLocation } from 'react-router-dom';
import Popup from './Popup'; // Import the Popup component
import kitchenIcon from "../../images/Group62665.png";
const ServiceForm = () => {
  const location = useLocation();
  const { backgroundImg } = location.state;

  // Now you can use the backgroundImg data in your component
  
};
const data=[
  {
    label: "AIR CONDITIONER - DOMESTIC (WINDOW AND SPLIT AC)",
    value: "6373436f1307e26d44ac8cdb",
    image: kitchenIcon,
  },
]
const ServiceType = () => {
  // Initialize showPopup to true to make it appear when the screen loads
  const handleClosePopup = () => {
    console.log("========")
    setShowPopup(false);
    console.log("==kfkff======")
     // Close the popup
  };

  const history = useHistory();
  const [showPopup, setShowPopup] = useState(true);
  const handleButtonClick = (backgroundImg) => {
   
    // history.push("/service-form", { backgroundImg:ac });
    history.push({
      pathname: "/service-form",
       state: { ...data[0], backgroundImg: ac },
    })
  };

  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  return (
    <div className="service-container">
      {/* Popup component */}
     
      <Popup show={showPopup} onClose={() => handleClosePopup()} />

      <div className="service-options">
        <h1 style={{ fontSize: "24px", marginBottom: "20px" }}>Select a Service:</h1>
        <div className="service-icons">
          <div className='a'>
            <IconBox>
              <ClickableImage onClick={() => handleButtonClick(service)} src={service} alt="Service" />
              <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>Service</span>
            </IconBox>
          </div>
          <div className='a1'>
            <IconBox>
              <ClickableImage onClick={() => handleButtonClick(repair)} src={repair} alt="Repair" />
              <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>Repair & Gas Refill</span>
            </IconBox>
          </div>
          <div className='a2'>
            <IconBox>
              <ClickableImage onClick={() => handleButtonClick(install)} src={install} alt="Install" />
              <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>Install & Uninstall</span>
            </IconBox>
          </div>
        </div>
      </div>

      <div className="slider-container">
        <Slider {...settings}>
          <img src={acimage} alt="AC Repair" />
          <img src={acimage1} alt="AC Repair" />
        </Slider>
      </div>
      
    </div>
  );
};

const ClickableImage = ({ onClick, src, alt, label }) => (
  <div className="clickable-image" onClick={onClick}>
    <img src={src} alt={alt} />
    <p>{label}</p>
  </div>
);

const IconBox = ({ children }) => (
  <div className="icon-box">{children}</div>
);

export default ServiceType;
