
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import splitAcIcon from '../../images/splittt.webp';
import windowAcIcon from '../../images/windowww.webp';
import './Popup.css';

const Popup = ({ show, onClose }) => {
  const [isVisible, setIsVisible] = useState(show);

  useEffect(() => {
    setIsVisible(show);
  }, [show]); // Update isVisible state when the show prop changes

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <>
      {/* Background overlay with blur effect */}
      {show && (
        <div className={`popup-overlay ${isVisible ? 'show' : ''}`} onClick={handleClose}></div>
      )}
  
      {/* Popup content */}
      {show && (
        <div className={`popup-container ${isVisible ? 'show' : ''}`}>
          <div className={`popup-content ${isVisible ? 'visible' : ''}`}>
            <div className='text'>
              <h2>Type of AC</h2>
            </div>
            <div className="ac-icons-container">
              <div className="ac-icon-wrapper" onClick={handleClose}>
                <img src={windowAcIcon} alt="Window AC" className="ac-icon" />
                <span>Window</span>
              </div>
              <div className="ac-icon-wrapper" onClick={handleClose}>
                <img src={splitAcIcon} alt="Split AC" className="ac-icon" />
                <span>Split AC</span>
              </div>
            </div>
            {/* Close icon */}
            {/* <FontAwesomeIcon icon={faTimes} className="close-icon" onClick={handleClose} /> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
