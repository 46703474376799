 export const API_URL = "https://api.onit.services";
// export const API_URL = "https://api.onit.fit";
//export const API_URL = "https://api.onitservices.in";


//Testing API Key
export const RAZORPAY_KEY = "rzp_test_xbtU9g8sjSB3vN";

// export const RAZORPAY_KEY = "rzp_live_yr00EgqO9pvjDt";

export const PAYMENT_NAME = "onit";
export const PAYMENT_DESCRIPTION = "Ticket acceptance";
